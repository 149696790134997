import { useEffect, useState } from "react";
import Loader from "../../../components/loader";
import { getSortText, restApi } from "../../../helpers/constant";
import Table from "../components/table";

export type THistoryEmail = {
  id: number;
  name: string;
  email: string;
  message: string;
};

const HistoryEmailAdmin = () => {
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [historyEmail, setHistoryEmail] = useState<THistoryEmail[]>([]);
  const [total, setTotal] = useState(0);

  const getData = async (page = 1) => {
    setLoading(true);
    await restApi({
      path: "/admin/history-email?page" + page,
      method: "get",
    }).then(({ data }: any) => {
      setTotal(data?.data?.total);
      setHistoryEmail(data?.data?.data);
      setPage(page);
    });
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const data: THistoryEmail[] = historyEmail.map((o, i) => {
    return { ...o, message: getSortText(o.message, 80) };
  });

  return (
    <>
      <Loader show={loading} />
      <div className="card text-neutral-800">
        <div className="mb-3 items-start justify-between lg:flex">
          <div className="text-[20px]">History Email</div>
        </div>

        <Table
          data={data}
          totalData={total}
          column={[
            { name: "Nama", selector: "name", dataClass: "w-[200px]" },
            { name: "Email", selector: "email", dataClass: "w-[250px]" },
            { name: "Message", selector: "message" },
          ]}
          onChangePage={getData}
          page={page}
        />
      </div>
    </>
  );
};

export default HistoryEmailAdmin;
