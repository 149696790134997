import { Input, notification, Switch } from "antd";
import { useState } from "react";
import { TArticle } from ".";
import SelectFile from "../../../components/select-file";
import { restApi } from "../../../helpers/constant";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    ["clean"],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];

type IProps = {
  toggle: boolean;
  setToggle: (e: boolean) => void;
  data: TArticle;
  defaultData: TArticle;
  onChange: (e: TArticle) => void;
  onFetch?: () => void;
  onContent: (e: string) => void;
};

const TambahDataArticle = ({
  toggle,
  setToggle,
  data,
  defaultData,
  onChange,
  onFetch,
  onContent,
}: IProps) => {
  const [isYoutube, setIsYoutube] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);

    const payload = { ...data };
    if (isYoutube) payload.img = "";
    else payload.video = "";

    await restApi({
      path: "/admin/article" + (payload.id ? `/${payload.id}` : ""),
      method: payload.id ? "put" : "post",
      data: payload,
    }).then(() => {
      notification.success({ message: "Submit data berhasil" });
      handleClose();
      onFetch && onFetch();
    });
    setLoading(false);
  };

  const handleClose = () => {
    setToggle(false);
    onChange(defaultData);
  };

  return (
    <div className="block">
      <button
        id="btn-tambah-data-Article"
        className="flex items-center gap-2 rounded-full bg-primary py-[12px] px-[16px] text-[14px] text-white"
        onClick={() => setToggle(!toggle)}
      >
        Tambah Data <i className="ri-add-line"></i>
      </button>
      <div className={toggle ? "block" : "hidden"}>
        <div
          className="fixed inset-0 z-50 flex w-screen items-center justify-center bg-slate-900/50"
          onClick={() => handleClose()}
        />
        <div className="fixed inset-0 top-[20px] bottom-[20px] z-50 mx-auto flex flex-col justify-between overflow-x-auto rounded-[20px] bg-white lg:w-[90%]">
          <div className="p-[24px]">
            <div className="flex justify-between">
              <h3 className="mb-[40px] text-[20px] text-neutral-700">
                Tambah Data
              </h3>
              <i
                className="ri-close-line h-fit cursor-pointer text-[20px]"
                onClick={() => handleClose()}
              />
            </div>
            <div className="flex flex-col gap-[15px]">
              <div className="grid grid-cols-2 gap-5">
                <div>
                  <div className="mb-2">Judul Artikel</div>
                  <Input
                    value={data?.titel}
                    onChange={(e) =>
                      onChange({ ...data, titel: e.target.value })
                    }
                  />
                </div>
                <div>
                  <div className="mb-1 flex flex-row gap-2 items-center">
                    <div>Mode File:</div>
                    <Switch
                      className="bg-gray-600"
                      checkedChildren="Link Youtube"
                      unCheckedChildren="Gambar"
                      checked={isYoutube}
                      onChange={(e) => setIsYoutube(e)}
                    />
                  </div>
                  {isYoutube ? (
                    <Input
                      value={data?.video}
                      onChange={(e) =>
                        onChange({ ...data, video: e.target.value })
                      }
                      placeholder="Link youtube"
                    />
                  ) : (
                    <SelectFile
                      value={data.img}
                      label="Upload Gambar"
                      onChange={(e) => onChange({ ...data, img: e })}
                      prefix="article"
                    />
                  )}
                </div>
              </div>
              <div>
                <ReactQuill
                  value={data.content}
                  onChange={(e) => onContent(e)}
                  modules={modules}
                  formats={formats}
                  className="h-[300px] mb-[20px]"
                />
              </div>
            </div>
          </div>
          <div className="flex justify-end gap-[24px] bg-neutral-100 py-[16px] px-[24px]">
            <button
              className="px-[16px] py-[12px]"
              onClick={() => handleClose()}
              disabled={loading}
            >
              Batal
            </button>
            <button
              disabled={loading}
              className="rounded-full bg-primary px-[16px] py-[12px] text-white"
              onClick={() => handleSubmit()}
            >
              {loading ? "Loading..." : "Simpan"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TambahDataArticle;
