import { Input, notification } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import { isMobile } from "../../../../helpers/constant";
import { TSetting } from "../../../admin/setting";
import { reformatPhoneNumber, restApi } from "../../../../helpers/constant";
import Loader from "../../../../components/loader";

const ContactBeranda = ({ setting }: { setting: TSetting[] }) => {
  const location = setting.find((o) => o.name === "Location");
  const email = setting.find((o) => o.name === "Email");
  const phone = setting.find((o) => o.name === "Phone (+62)");

  const [loading, setLoading] = useState(false);
  const [mobile, setMobile] = useState(true);
  const [message, setMessage] = useState({ name: "", email: "", message: "" });

  useEffect(() => {
    setMobile(isMobile());
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    setLoading(true);
    await restApi({ path: "/send-email", method: "post", data: message })
      .then(() => {
        notification.success({ message: "Email berhasil terkirim" });
        setMessage({ name: "", email: "", message: "" });
      })
      .catch((e) => {
        notification.error({ message: e.message });
      });
    setLoading(false);
  };

  return (
    <>
      <Loader show={loading} />
      <div className="lg:p-14 p-[20px] bg-primary" id="#contact">
        <div className="lg:text-[36px] text-[25px] text-white font-semibold text-center">
          Contact Us
        </div>
        <div className="flex flex-col lg:flex-row mt-8">
          <div className="lg:w-[40%] pr-[40px]">
            <div className="flex flex-col lg:flex-row lg:items-center mb-6">
              {!mobile && (
                <div className="lg:w-[50px] flex justify-center">
                  <img src="/img/location.svg" alt="location" />
                </div>
              )}
              <div className="ml-3">
                <div className="text-white text-[24px]">Location:</div>
                <div className="text-white text-[16px] mt-2 leading-7">
                  {location?.value}
                </div>
              </div>
            </div>

            <div className="flex flex-col lg:flex-row lg:items-center mb-6">
              {!mobile && (
                <div className="lg:w-[50px] flex justify-center">
                  <img src="/img/email.png" alt="location" />
                </div>
              )}
              <div className="ml-3">
                <div className="text-white text-[24px]">Email:</div>
                <div className="text-white text-[16px] mt-2">
                  {email?.value}
                </div>
              </div>
            </div>

            <div className="flex flex-col lg:flex-row lg:items-center mb-6">
              {!mobile && (
                <div className="lg:w-[50px] flex justify-center">
                  <img src="/img/call.svg" alt="location" />
                </div>
              )}
              <div className="ml-3">
                <div className="text-white text-[24px]">Call:</div>
                <div className="text-white text-[16px] mt-2">
                  {reformatPhoneNumber(phone?.value)}
                </div>
              </div>
            </div>
          </div>
          <div className="lg:w-[60%]">
            <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4 mt-5">
                <Input
                  placeholder="Your Name"
                  required
                  value={message.name}
                  onChange={(e) =>
                    setMessage((prev) => ({ ...prev, name: e.target.value }))
                  }
                />
                <Input
                  placeholder="Your Email"
                  required
                  type="email"
                  value={message.email}
                  onChange={(e) =>
                    setMessage((prev) => ({ ...prev, email: e.target.value }))
                  }
                />
              </div>
              <TextArea
                rows={5}
                placeholder="Message"
                required
                value={message.message}
                onChange={(e) =>
                  setMessage((prev) => ({ ...prev, message: e.target.value }))
                }
              />
              <button
                type="submit"
                className={
                  "mt-8 rounded-lg bg-second text-white lg:w-[250px] w-full " +
                  "cursor-pointer rounded-3xl py-3 text-center px-5"
                }
              >
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactBeranda;
