import { NavLink } from "react-router-dom";

const navClass = (navData: any) => {
  const classname =
    "transition-all duration-300 py-[12px] font-[400] flex flex-row items-center gap-1 ";
  if (navData.isActive) {
    return classname + " text-primary font-extra-bold";
  }
  return classname;
};

function Sidebar() {
  return (
    <div className="flex flex-col p-[16px] text-neutral-500">
      <div className="flex items-center cursor-pointer">
        <img src={"/logo.png"} alt="logo" width={100} />
      </div>
      <div className="mt-8 mb-3">NAVIGASI</div>
      <NavLink className={navClass} to="/admin/articles">
        <i className="ri-file-list-line w-[20px]"></i>
        Articles
      </NavLink>
      {/* <NavLink className={navClass} to="/admin/slide-show">
        <i className="ri-bar-chart-grouped-line w-[20px]"></i>
        Slide Show
      </NavLink>
      <NavLink className={navClass} to="/admin/history-message">
        <i className="ri-award-line w-[20px]"></i>
        History Message
      </NavLink> */}
      <NavLink className={navClass} to="/admin/setting">
        <i className="ri-user-settings-line w-[20px]"></i>
        Setting
      </NavLink>
    </div>
  );
}

export default Sidebar;
