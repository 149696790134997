import { TSetting } from "../../../admin/setting";

const SubdivisionBeranda = ({ setting }: { setting: TSetting[] }) => {
  const link = setting.find((o) => o.name === "CC & Rs");

  return (
    <div
      className="flex flex-col items-center lg:p-14 p-[20px]"
      id="#subdivision"
    >
      <div className="lg:text-[25px] text-[20px] text-soft-black font-semibold text-center">
        <div className="lg:mb-3 mb-2">TOV Subdivision</div>
        <div className="leading-8">
          Convenants, Conditions, and Restrictions (CC & Rs)
        </div>
      </div>
      <a
        href={link?.value}
        className={
          "mt-8 rounded-lg bg-second text-white " +
          "cursor-pointer rounded-3xl py-3 text-center px-5"
        }
        target="_blank"
        rel="noreferrer"
      >
        Click here to download
      </a>
    </div>
  );
};

export default SubdivisionBeranda;
