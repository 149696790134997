import { Route, Routes } from "react-router-dom";

import Landing from "./pages/customer/landing";
import Articles from "./pages/customer/articles";
import DetailArticle from "./pages/customer/articles/{id}";

import Login from "./pages/admin/login";
import AdminLayout from "./pages/admin/components/layout";
import ArticleAdmin from "./pages/admin/article";
import SettingAdmin from "./pages/admin/setting";
import HistoryEmailAdmin from "./pages/admin/history-email";

const App = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/articles" element={<Articles />} />
        <Route path="/articles/:id" element={<DetailArticle />} />

        <Route path="/login" element={<Login />} />
        <Route path="/admin" element={<AdminLayout />}>
          <Route index element={<ArticleAdmin />} />
          <Route path="articles" element={<ArticleAdmin />} />
          <Route path="slide-show" element={<ArticleAdmin />} />
          <Route path="history-message" element={<HistoryEmailAdmin />} />
          <Route path="setting" element={<SettingAdmin />} />
        </Route>
      </Routes>
    </div>
  );
};

export default App;
