import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Loader from "../../../../components/loader";
import { getSortText, restApi } from "../../../../helpers/constant";
import { TArticle } from "../../../admin/article";
import { TSetting } from "../../../admin/setting";
import FooterGlobal from "../components/footer";
import HeaderGlobal from "../components/header";

type IData = {
  data: TArticle[];
  total: number;
  next_page_url?: string;
  current_page: number;
};

const DetailArticle = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const [setting, setSetting] = useState<TSetting[]>([]);
  const [data, setData] = useState<TArticle>();
  const [listData, setListData] = useState<IData>({
    data: [],
    total: 0,
    current_page: 1,
  });

  const getData = async () => {
    setLoading(true);

    const [article, articles] = await Promise.all([
      restApi({
        path: `/article/${id}`,
        method: "get",
      }).then(({ data }: any) => data?.data?.data),
      restApi({
        path: `/article`,
        method: "get",
      }).then(({ data }: any) => data?.data),
      restApi({
        path: `/setting`,
        method: "get",
      }).then(({ data }: any) => data?.data?.data),
    ]);

    setData(article[0]);
    setListData({
      ...articles,
      data: articles.data.filter((o: any) => o.id !== article?.[0]?.id),
    });
    setSetting(setting);

    setLoading(false);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shareURL = encodeURIComponent(window.location.href);
  const shareText = encodeURIComponent(data?.titel || "");

  if (data?.titel || loading) {
    return (
      <>
        <Loader show={loading} />
        <HeaderGlobal />
        <div className="bg-primary lg:text-[40px] text-[20px] font-semibold text-center lg:py-[50px] py-[20px] text-white">
          Mandalika, Lombok, West Nusa Tenggara, Indonesia
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-7 lg:px-20 lg:py-[20px] p-[20px] gap-8">
          <div className="col-span-1 lg:col-span-5">
            <div className="flex justify-center">
              {data?.video ? (
                <iframe
                  title={data?.titel}
                  src={data.video}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="w-full lg:h-[450px] h-[180px] rounded-lg"
                />
              ) : (
                <img
                  src={data?.img}
                  className="w-full lg:h-[450px] h-[180px] rounded-lg"
                  alt={data?.titel}
                  onError={(e: any) => {
                    e.target.src = "/img/mandalika.png";
                  }}
                />
              )}
            </div>

            <div className="mt-[20px]">
              <div className="font-semibold text-[20px] lg:text-[24px]">
                {data?.titel}
              </div>
              <p
                className="mt-[20px] leading-7 font-normal text-justify"
                dangerouslySetInnerHTML={{ __html: data?.content }}
              />
            </div>
          </div>
          <div className="col-span-1 lg:col-span-2">
            <div className="shadow-lg rounded-lg p-5 bg-white">
              <div className="bg-[#F6F9FF] px-2 py-3 rounded-lg text-[#135CC7]">
                Share
              </div>
              <div className="flex justify-between mt-4">
                <a
                  href={`https://www.linkedin.com/sharing/share-offsite/?url=${shareURL}`}
                  rel="noreferrer"
                  target={"_blank"}
                  className="cursor-pointer"
                >
                  <img src="/img/linkedin.svg" alt="linkedin" />
                </a>
                <a
                  href={`https://www.facebook.com/sharer/sharer.php?u=${shareURL}`}
                  rel="noreferrer"
                  target={"_blank"}
                  className="cursor-pointer"
                >
                  <img src="/img/facebook.svg" alt="facebook" />
                </a>
                <a
                  href={`https://twitter.com/intent/tweet?url=${shareURL}&text=${shareText}`}
                  rel="noreferrer"
                  target={"_blank"}
                  className="cursor-pointer"
                >
                  <img src="/img/twitter.svg" alt="twitter" />
                </a>
                <a
                  href={`https://wa.me/?text=${shareText}%20${shareURL}`}
                  rel="noreferrer"
                  target={"_blank"}
                  className="cursor-pointer"
                >
                  <img src="/img/whatsapp.svg" alt="whatsapp" />
                </a>
              </div>
            </div>

            <div className="grid grid-cols-1  mt-5 gap-4">
              {listData.data.slice(0, 2).map((o, i) => (
                <Link
                  key={i}
                  to={`/articles/${o.id}`}
                  className={"bg-white rounded-xl shadow-lg"}
                >
                  {o.video ? (
                    <iframe
                      title={o.titel}
                      src={o.video}
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      className="h-[230px] rounded-t-lg w-full"
                    />
                  ) : (
                    <img
                      src={o.img}
                      className="h-[230px] rounded-t-lg w-full"
                      alt={o.titel}
                      onError={(e: any) => {
                        e.target.src = "/img/mandalika.png";
                      }}
                    />
                  )}
                  <div className="p-[20px]">
                    <div className="text-[16px] lg:text-[20px] text-soft-black font-semibold">
                      {getSortText(o.titel, 40)}
                    </div>
                    <div className="text-[12px] lg:text-[16px] text-soft-black mt-[20px] text-justify">
                      {getSortText(o.content, 150)}
                    </div>
                    <div className="text-[12px] lg:text-[16px] text-primary mt-[25px]">
                      {moment(o.created_at).format("DD MMM YYYY")}
                    </div>
                  </div>
                </Link>
              ))}
            </div>
            {listData.total > 2 && (
              <Link to={"/articles"}>
                <div
                  className={
                    "mt-8 rounded-lg bg-second text-white " +
                    "cursor-pointer rounded-3xl py-3 text-center px-5"
                  }
                >
                  See All a Articles
                </div>
              </Link>
            )}
          </div>
        </div>
        <FooterGlobal setting={setting} />
      </>
    );
  }

  return (
    <>
      <Loader show={loading} />
      <HeaderGlobal />
      <div className="bg-primary lg:text-[40px] text-[20px] font-semibold text-center lg:py-[50px] py-[20px] text-white">
        Mandalika, Lombok, West Nusa Tenggara, Indonesia
      </div>
      <div className="flex flex-col items-center">
        <div className="text-center mt-5 text-[40px] text-gray-500">
          Uuppss.. Data tidak ditemukan {">_<"}
        </div>
        {listData.total > 2 && (
          <Link to={"/articles"}>
            <div
              className={
                "mt-8 rounded-lg bg-second text-white w-[250px] " +
                "cursor-pointer rounded-3xl py-3 text-center px-5"
              }
            >
              See All a Articles
            </div>
          </Link>
        )}
      </div>
      <FooterGlobal setting={setting} />
    </>
  );
};

export default DetailArticle;
