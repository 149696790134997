import { Input, notification } from "antd";
import { useEffect, useState } from "react";
import Loader from "../../../components/loader";
import { restApi } from "../../../helpers/constant";
import Table from "../components/table";

export type TSetting = { id: number; name: string; value: any };

const SettingAdmin = () => {
  const [setting, setSetting] = useState<TSetting[]>([]);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(true);
    await restApi({ path: "/admin/setting", method: "get" }).then(
      ({ data }: any) => {
        setSetting(data?.data?.data);
      }
    );
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleUpdate = async (data: TSetting) => {
    setLoading(true);
    await restApi({
      path: `/admin/setting/${data.id}`,
      method: "put",
      data,
    }).then(() => {
      notification.success({ message: "Submit data berhasil" });
    });
    setLoading(false);
  };

  const data = setting.map((o, i) => {
    return {
      ...o,
      value: (
        <Input
          value={o.value}
          onChange={(e) => {
            const row = [...setting];
            row[i] = { ...row[i], value: e.target.value };
            setSetting(row);
            const updateTime = setTimeout(() => {
              handleUpdate(row[i]);
              clearTimeout(updateTime);
            }, 1000);
          }}
        />
      ),
    };
  });

  return (
    <>
      <Loader show={loading} />
      <div className="card text-neutral-800">
        <div className="mb-3 items-start justify-between lg:flex">
          <div className="text-[20px]">Setting</div>
        </div>
        <Table
          data={data}
          totalData={data.length}
          column={[
            { name: "Nama Setting", selector: "name", dataClass: "w-[200px]" },
            { name: "Value", selector: "value" },
          ]}
        />
      </div>
    </>
  );
};

export default SettingAdmin;
