import { useState } from "react";
import { uploadFile, validationImage } from "../helpers/constant";
import Loader from "./loader";

type IProps = {
  onChange: (e: string) => void;
  label?: string;
  prefix?: string;
  value: string;
};

const SelectFile = ({
  onChange,
  label = "Upload",
  prefix = "files",
  value,
}: IProps) => {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Loader show={loading} />
      <div className="flex w-full">
        {value && <img src={value} alt="img" className="w-[60px] mr-2" />}
        <div className="relative overflow-hidden rounded-xl border border-neutral-300 w-full">
          <div className="flex items-center">
            <div className="w-fit text-[12px] bg-neutral-200 py-[16px] px-[8px] font-light">
              {label}
            </div>
            <div className="ml-2 text-[12px] text-gray-400">
              format: png, jpg, jpeg
            </div>
          </div>
          <input
            type="file"
            className="absolute inset-0 h-full w-full cursor-pointer opacity-0"
            accept=".png,.jpg,.jpeg"
            onChange={(e) => {
              const file: any = e.target.files?.[0];

              if (!validationImage(file)) return;

              setLoading(true);
              uploadFile({
                file,
                setImage: (url) => {
                  onChange(url);
                  e.target.value = "";
                  setLoading(false);
                },
                prefix,
              });
            }}
          />
        </div>
      </div>
    </>
  );
};

export default SelectFile;
