import { Link } from "react-router-dom";
import { TSetting } from "../../../admin/setting";

const FooterGlobal = ({ setting }: { setting: TSetting[] }) => {
  const sosialMedia = setting.find((o) => o.name === "Sosial Media");

  return (
    <div className="lg:p-14 p-[20px] border-t-2 border-t-primary">
      <div className="lg:text-[30px] text-[25px] font-semibold text-primary mb-5 leading-8">
        Tanjung Ocean View Association
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 w-full lg:w-[50%]">
        <div className="gap-3 flex flex-col">
          <Link
            to={"/#subdivision"}
            className="text-primary text-[18px] cursor-pointer"
          >
            TOV Subdivision
          </Link>
          <Link
            to={"/#assessment"}
            className="text-primary text-[18px] cursor-pointer"
          >
            Annual Assessment
          </Link>
          <Link
            to={"/#articles"}
            className="text-primary text-[18px] cursor-pointer"
          >
            Articles
          </Link>
          {/* <Link
            to={"/#board"}
            className="text-primary text-[18px] cursor-pointer"
          >
            Board of TOVA
          </Link> */}
          <Link
            to={"/#contact"}
            className="text-primary text-[18px] cursor-pointer"
          >
            Contact Us
          </Link>
        </div>
        <div className="gap-3 flex flex-col mt-4 lg:mt-0">
          <div className="text-primary text-[18px] font-semibold">
            Sosial Media
          </div>
          <a
            className="flex items-center cursor-pointer"
            target={"_blank"}
            href={sosialMedia?.value}
            rel="noreferrer"
          >
            <div>
              <img src="/img/sosial-media.svg" alt="sosial media" />
            </div>
            <div className="ml-2 text-primary text-[18px]">tov_mandalika</div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default FooterGlobal;
