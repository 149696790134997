const Loader = ({ show }: { show: boolean }) => {
  if (!show) return <div />;
  return (
    <div
      className={
        "bg-black opacity-50 text-[#fff] flex fixed " +
        "h-full w-[100%] justify-center items-center " +
        "font-bold top-0 z-50 left-0"
      }
    >
      Loading ...
    </div>
  );
};

export default Loader;
