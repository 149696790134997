import { Input, notification } from "antd";
import { useEffect, useState } from "react";
import { ReactCookieProps, withCookies } from "react-cookie";
import Loader from "../../../components/loader";
import { restApi } from "../../../helpers/constant";

const { REACT_APP_STORAGE_KEY = "" } = process.env;

const Login = ({ cookies }: ReactCookieProps) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({
    username: "",
    password: "",
  });

  const checkAuth = async () => {
    try {
      const token = cookies?.get(REACT_APP_STORAGE_KEY || "");
      if (token) throw new Error("authorized");
      return setShow(true);
    } catch (error) {
      console.log(error);
      return window.location.replace("/admin");
    }
  };

  useEffect(() => {
    checkAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    setLoading(true);

    await restApi({ path: "/login", method: "post", data: payload })
      .then(({ data }: any) => {
        if (!data?.access_token) throw new Error("Account not valid!");

        const maxAge = 1 * 24 * 60 * 60;
        cookies?.set(REACT_APP_STORAGE_KEY, data.access_token, {
          maxAge,
        });

        return window.location.replace("/admin");
      })
      .catch((e) => {
        notification.error({ message: e.message });
      });

    setLoading(false);
  };

  if (show) {
    return (
      <>
        <Loader show={loading} />
        <div className="flex justify-center lg:items-center h-screen bg-[#e9e8e8]">
          <div className="bg-white p-[20px] w-full lg:w-[calc(100%/3)] rounded-lg">
            <div className="flex items-center cursor-pointer justify-center mt-3 mb-7">
              <img src={"/logo.png"} alt="logo" width={150} />
            </div>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <div className="mb-2 text-gray-500">Username</div>
                <Input
                  required
                  value={payload.username}
                  onChange={(e) =>
                    setPayload({ ...payload, username: e.target.value })
                  }
                />
              </div>
              <div className="mb-4">
                <div className="mb-2 text-gray-500">Password</div>
                <Input
                  required
                  value={payload.password}
                  type="password"
                  onChange={(e) =>
                    setPayload({ ...payload, password: e.target.value })
                  }
                />
              </div>
              <button className="rounded-lg bg-primary py-[12px] px-[16px] text-[14px] text-white w-full text-center">
                Masuk <i className="ri-submit-line"></i>
              </button>
            </form>
          </div>
        </div>
      </>
    );
  }

  return <div />;
};

export default withCookies(Login);
