import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAWyz_8wPqGd3ef35hlun-6w4D_AZDdCIE",
  authDomain: "tovassociation.firebaseapp.com",
  projectId: "tovassociation",
  storageBucket: "tovassociation.appspot.com",
  messagingSenderId: "294474143952",
  appId: "1:294474143952:web:51b69d40d8e864448be6d5",
  measurementId: "G-LJNNFP1LK7",
};

export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
