import { useEffect, useState } from "react";
import Loader from "../../../components/loader";
import { restApi } from "../../../helpers/constant";
import { TArticle } from "../../admin/article";
import { TSetting } from "../../admin/setting";
import ArticleBeranda from "./components/article";
import AssessmentBeranda from "./components/assessment";
import ContactBeranda from "./components/contact";
import FooterBeranda from "./components/footer";
import HeaderBeranda from "./components/header";
import HeroBeranda from "./components/hero";
import SubdivisionBeranda from "./components/subdivision";

type IData = {
  data: TArticle[];
  total: number;
  next_page_url?: string;
  current_page: number;
};

const Landing = () => {
  const [loading, setLoading] = useState(false);
  const [setting, setSetting] = useState<TSetting[]>([]);
  const [data, setData] = useState<IData>({
    data: [],
    total: 0,
    current_page: 1,
  });

  const getData = async (page = 1) => {
    setLoading(true);

    const [article, setting] = await Promise.all([
      restApi({
        path: `/article?page=${page}`,
        method: "get",
      }).then(({ data }: any) => data?.data),
      restApi({
        path: `/setting`,
        method: "get",
      }).then(({ data }: any) => data?.data?.data),
    ]);

    setData(article);
    setSetting(setting);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const time = setTimeout(() => {
      scrollToHash();
      clearTimeout(time);
    }, 1000);
  }, [loading]);

  const scrollToHash = () => {
    try {
      const hash = window.location.hash;
      if (hash) {
        const targetElement = document.getElementById(hash);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth" });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Loader show={loading} />
      <HeaderBeranda />
      <HeroBeranda />
      <SubdivisionBeranda setting={setting} />
      <AssessmentBeranda setting={setting} />
      <ArticleBeranda {...data} />
      <ContactBeranda setting={setting} />
      <FooterBeranda setting={setting} />
    </>
  );
};

export default Landing;
