import { Pagination, Popconfirm } from "antd";
import { forwardRef } from "react";
import { currencyFormat } from "../../../helpers/constant";

type IProps = {
  data: any[];
  column: {
    columnClass?: string;
    name: string;
    selector: any;
    dataClass?: string;
  }[];
  totalData: number;
  onEdit?: (e?: any) => void;
  onDelete?: (e: number) => void;
  onChangePage?: (e: number) => void;
  page?: number;
};

const handleFormat = (e: any) => {
  if ((typeof e === "string" && !isNaN(Number(e))) || typeof e === "number") {
    if (e.toString().length > 3) return currencyFormat(e);
  }

  return e;
};

const Table = forwardRef((props: IProps, ref) => {
  const {
    data = [],
    column = [],
    totalData = 0,
    onEdit,
    onDelete,
    onChangePage,
    page = 1,
  } = props;

  return (
    <>
      <div className="relative overflow-x-auto ">
        <table className="w-full text-left text-sm text-gray-500 ">
          <thead className="bg-neutral-200 text-[14px] font-light text-gray-700">
            <tr className="">
              {column.map((col, keyCol) => (
                <th
                  key={keyCol}
                  scope="col"
                  className={
                    "font-ligh px-[16px] py-[14px] font-normal " +
                    col.columnClass
                  }
                >
                  {col.name}
                </th>
              ))}
            </tr>
          </thead>

          <tbody className="text-[14px]">
            {data?.map((item, key) => {
              return (
                <tr
                  key={key}
                  className={key % 2 !== 0 ? "bg-[#F2F4F7]" : "bg-white "}
                >
                  {column.map((col, keyCol) => {
                    if (col?.name === "Aksi") {
                      return (
                        <td key={keyCol} className="px-[16px] py-[21px]">
                          <div className="flex gap-5">
                            {col?.selector.includes("edit") && (
                              <i
                                className="ri-edit-line text-[20px] cursor-pointer"
                                onClick={() => onEdit && onEdit(item.id)}
                              />
                            )}
                            {col?.selector.includes("delete") && (
                              <Popconfirm
                                title="Lanjut mengapus data?"
                                onConfirm={() => onDelete && onDelete(item.id)}
                                okText="Lanjut"
                                cancelText="Batal"
                                okType="danger"
                              >
                                <i className="ri-delete-bin-line cursor-pointer text-[20px]" />
                              </Popconfirm>
                            )}
                          </div>
                        </td>
                      );
                    }
                    return (
                      <td
                        key={keyCol}
                        className={"px-[16px] py-[21px] " + col.dataClass}
                      >
                        {handleFormat(item?.[col?.selector])}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>

        {!!onChangePage && (
          <div className="mt-[42px] mb-2 justify-between lg:flex">
            <div className="mb-3 text-[14px] text-neutral-700">
              Menampilkan {data.length} dari {totalData} data
            </div>
            <Pagination
              total={totalData}
              current={page}
              pageSize={10}
              onChange={onChangePage}
            />
          </div>
        )}
      </div>
    </>
  );
});

Table.propTypes = {};

export default Table;
