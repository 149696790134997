import { Link } from "react-router-dom";
import { getSortText } from "../../../../helpers/constant";
import { TArticle } from "../../../admin/article";

type IProps = {
  data: TArticle[];
  total: number;
  next_page_url?: string;
  current_page: number;
};

const ArticleBeranda = ({ data, total }: IProps) => {
  if (total > 0) {
    return (
      <div
        className="flex flex-col items-center lg:p-14 p-[20px]"
        id="#articles"
      >
        <div className="lg:text-[25px] text-[20px] text-soft-black font-semibold text-center">
          <div className="leading-8">TOV News and Mandalika</div>
        </div>
        <div className="text-soft-black text-sm mt-6 text-center">
          Here are all the articles about the latest information in Mandalika.
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 mt-5 gap-4">
          {data.slice(0, 6).map((o, i) => (
            <Link
              key={i}
              to={`/articles/${o.id}`}
              className={"bg-gray-700 rounded-xl flex flex-col"}
            >
              {o.video ? (
                <iframe
                  title={o.titel}
                  src={o.video}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="opacity-50 lg:-mb-[60px] -mb-[45px] lg:h-[230px] h-[200px] rounded-lg lg:min-w-[350px]"
                />
              ) : (
                <img
                  src={o.img}
                  className="opacity-50 lg:-mb-[60px] -mb-[45px] lg:h-[230px] h-[200px] rounded-lg lg:min-w-[350px]"
                  alt={o.titel}
                  onError={(e: any) => {
                    e.target.src = "/img/mandalika.png";
                  }}
                />
              )}
              <div
                className={
                  "text-[16px] lg:text-[20px] text-[#fff] text-center font-[600] " +
                  "relative z-10 lg:h-[60px] h-[45px] mx-5"
                }
              >
                {getSortText(o.titel, 50)}
              </div>
            </Link>
          ))}
        </div>
        {data.length > 6 && (
          <Link to={"/articles"}>
            <div
              className={
                "mt-8 rounded-lg bg-second text-white " +
                "cursor-pointer rounded-3xl py-3 text-center px-5"
              }
            >
              See All a Articles
            </div>
          </Link>
        )}
      </div>
    );
  }

  return <div className="h-3" />;
};

export default ArticleBeranda;
