import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { isMobile, restApi } from "../../../helpers/constant";
import { ReactCookieProps } from "react-cookie";
import Loader from "../../../components/loader";

const { REACT_APP_STORAGE_KEY = "" } = process.env;

const Header = (props: {
  toggleSidebar: any;
  sidebarOpen: any;
  cookies: ReactCookieProps["cookies"];
}) => {
  const { toggleSidebar, sidebarOpen, cookies } = props;

  const [loading, setLoading] = useState(false);
  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    setMobile(isMobile());
  }, []);

  const handleLogout = async () => {
    setLoading(true)
    await restApi({ path: "/admin/logout", method: "post", data: {} }).then(
      () => {
        cookies?.remove(REACT_APP_STORAGE_KEY);
        window.location.replace("/");
      }
    );
    setLoading(true)
  };

  return (
    <>
      <Loader show={loading} />
      <header
        className={
          "fixed top-0 left-0 z-10 flex h-[64px] w-full transition-all duration-300  " +
          (sidebarOpen ? (mobile ? "pl-[310px] " : "pl-[260px] ") : "pl-0 ")
        }
      >
        <div className="flex w-full flex-row items-center justify-between border-b bg-white">
          <div className="grid">
            <button
              onClick={toggleSidebar}
              className="p-[16px] transition-all duration-300 hover:bg-slate-100"
            >
              <FontAwesomeIcon icon={faBars} color={"#344054"} />
            </button>
          </div>

          <div className="mr-7 flex">
            <div
              className="flex cursor-pointer items-center"
              onClick={() => handleLogout()}
            >
              <div className="ml-3 text-gray-500 font-[600]">Logout</div>
              <i className="ri-logout-box-r-line text-gray-500 ml-1" />
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
