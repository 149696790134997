import { Footer } from "antd/es/layout/layout";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loader from "../../../components/loader";
import { getSortText, restApi } from "../../../helpers/constant";
import { TArticle } from "../../admin/article";
import { TSetting } from "../../admin/setting";
import FooterGlobal from "./components/footer";
import HeaderGlobal from "./components/header";

type IData = {
  data: TArticle[];
  total: number;
  next_page_url?: string;
  current_page: number;
};

const ArticlesPage = () => {
  const [loading, setLoading] = useState(false);
  const [setting, setSetting] = useState<TSetting[]>([]);
  const [listData, setData] = useState<IData>({
    data: [],
    total: 0,
    current_page: 1,
  });

  const getData = async (page = 1) => {
    setLoading(true);

    const [article, setting] = await Promise.all([
      restApi({
        path: `/article?page=${page}`,
        method: "get",
      }).then(({ data }: any) => data?.data),
      restApi({
        path: `/setting`,
        method: "get",
      }).then(({ data }: any) => data?.data?.data),
    ]);

    setSetting(setting);
    if (page === 1) {
      setData(article);
    } else {
      setData((prev) => ({
        ...article,
        data: prev.data.concat(article.data),
      }));
    }

    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const { data, next_page_url, current_page } = listData;

  return (
    <>
      <Loader show={loading} />
      <HeaderGlobal />
      <div className="bg-primary lg:text-[40px] text-[20px] font-semibold text-center lg:py-[50px] py-[20px] text-white">
        Mandalika, Lombok, West Nusa Tenggara, Indonesia
      </div>
      <div className="flex flex-col items-center lg:p-14 p-[20px]">
        <div className="lg:text-[28px] text-[20px] text-primary font-semibold text-center">
          <div className="leading-8">Artikel Terbaru</div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 mt-5 gap-4">
          {data.map((o, i) => (
            <Link
              key={i}
              to={`/articles/${o.id}`}
              className={"bg-white rounded-xl shadow-lg"}
            >
              {o.video ? (
                <iframe
                  title={o.titel}
                  src={o.video}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="h-[230px] rounded-t-lg w-full"
                />
              ) : (
                <img
                  src={o.img}
                  className="h-[230px] rounded-t-lg w-full"
                  alt={o.titel}
                  onError={(e: any) => {
                    e.target.src = "/img/mandalika.png";
                  }}
                />
              )}
              <div className="p-[20px]">
                <div className="text-[16px] lg:text-[20px] text-soft-black font-semibold">
                  {getSortText(o.titel, 40)}
                </div>
                <div className="text-[12px] lg:text-[16px] text-soft-black mt-[20px] text-justify">
                  {getSortText(o.content, 150)}
                </div>
                <div className="text-[12px] lg:text-[16px] text-primary mt-[25px]">
                  {moment(o.created_at).format("DD MMM YYYY")}
                </div>
              </div>
            </Link>
          ))}
        </div>
        {next_page_url && (
          <div
            className={
              "mt-8 rounded-lg bg-second text-white " +
              "cursor-pointer rounded-3xl py-3 text-center px-5"
            }
            onClick={() => {
              getData(current_page + 1);
            }}
          >
            Load More
          </div>
        )}
      </div>
      <FooterGlobal setting={setting} />
    </>
  );
};

export default ArticlesPage;
