import { Link } from "react-scroll";

const classname =
  "transition-all duration-300 py-[12px] font-[400] flex flex-row items-center gap-1 ";

function Sidebar() {
  return (
    <div className="flex flex-col p-[20px] text-neutral-500 mr-10">
      <div className="my-3">NAVIGASI</div>
      <Link
        smooth={true}
        duration={500}
        className={classname}
        to="#subdivision"
      >
        <i className="ri-bar-chart-grouped-line w-[20px]"></i>
        TOV Subdivision
      </Link>
      <Link smooth={true} duration={500} className={classname} to="#assessment">
        <i className="ri-file-list-line w-[20px]"></i>
        Annual Assessment
      </Link>
      <Link smooth={true} duration={500} className={classname} to="#articles">
        <i className="ri-award-line w-[20px]"></i>
        Articles
      </Link>
      {/* <Link smooth={true} duration={500} className={classname} to="#board">
        <i className="ri-database-line w-[20px]"></i>
        Board of TOVA
      </Link> */}
      <Link smooth={true} duration={500} className={classname} to="#contact">
        <i className="ri-chat-1-line w-[20px]"></i>
        Contact Us
      </Link>
    </div>
  );
}

export default Sidebar;
