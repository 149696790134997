import { notification, Switch } from "antd";
import { useEffect, useState } from "react";
import Loader from "../../../components/loader";
import { getSortText, restApi } from "../../../helpers/constant";
import Table from "../components/table";
import TambahDataArticle from "./tambah-data";

export type TArticle = {
  id: number;
  titel: string;
  content: any;
  img: any;
  video: string;
  is_active: any;
  created_at?: string;
};

const defaultData = {
  id: 0,
  titel: "",
  content: "",
  img: "",
  video: "",
  is_active: "0",
};

const ArticleAdmin = () => {
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [article, setArticle] = useState<TArticle[]>([]);
  const [selected, setSelected] = useState<TArticle>(defaultData);
  const [total, setTotal] = useState(0);

  const getData = async (page = 1) => {
    setLoading(true);
    await restApi({ path: "/admin/article?page" + page, method: "get" }).then(
      ({ data }: any) => {
        setTotal(data?.data?.total);
        setArticle(data?.data?.data);
        setPage(page);
      }
    );
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleDelete = async (id: number) => {
    setLoading(true);
    await restApi({
      path: `/admin/article/${id}`,
      method: "delete",
    }).then(() => {
      notification.success({ message: "Hapus data berhasil" });
      getData();
    });
    setLoading(false);
  };

  const handleActive = async (article: TArticle) => {
    setLoading(true);
    await restApi({
      path: `/admin/article/${article.id}`,
      method: "put",
      data: article,
    }).then(() => {
      notification.success({ message: "Ubah data berhasil" });
      getData(page);
    });
    setLoading(false);
  };

  const data: TArticle[] = article.map((o, i) => {
    return {
      ...o,
      content: getSortText(o.content, 70),
      titel: getSortText(o.titel, 40),
      is_active: (
        <Switch
          checked={Number(o.is_active) !== 0}
          className="bg-slate-500"
          onChange={(e) => {
            let data_ = [...article];
            data_[i] = { ...data_[i], is_active: e ? "1" : "0" };
            setArticle(data_);
            handleActive(data_[i]);
          }}
        />
      ),
    };
  });

  return (
    <>
      <Loader show={loading} />
      <div className="card text-neutral-800">
        <div className="mb-3 items-start justify-between lg:flex">
          <div className="text-[20px]">List Article</div>
          <TambahDataArticle
            setToggle={setToggle}
            toggle={toggle}
            data={selected}
            onChange={(e: TArticle) => setSelected(e)}
            defaultData={defaultData}
            onFetch={() => getData()}
            onContent={(e) => setSelected((prev) => ({ ...prev, content: e }))}
          />
        </div>

        <Table
          data={data}
          totalData={total}
          column={[
            { name: "Judul", selector: "titel" },
            { name: "Content", selector: "content" },
            { name: "Active", selector: "is_active" },
            { name: "Aksi", selector: ["edit", "delete"] },
          ]}
          onChangePage={getData}
          onEdit={(e: number) => {
            setSelected(article.find((o) => o.id === e) || defaultData);
            setToggle(true);
          }}
          onDelete={handleDelete}
        />
      </div>
    </>
  );
};

export default ArticleAdmin;
