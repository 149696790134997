import { TSetting } from "../../../admin/setting";

const AssessmentBeranda = ({ setting }: { setting: TSetting[] }) => {
  const linkExcel = setting.find((o) => o.name === "Status Payment Excel");
  const linkPDF = setting.find((o) => o.name === "Status Payment PDF");

  return (
    <div
      className="flex flex-col items-center lg:p-14 p-[20px] bg-primary"
      id="#assessment"
    >
      <div className="lg:text-[25px] text-[20px] text-white font-semibold text-center">
        <div className="leading-8">
          Annual Assessment Plus Penalties and Interest TOVA
        </div>
      </div>
      <div className="text-white text-sm mt-6 text-center lg:w-[60%] leading-7">
        <a
          href={linkExcel?.value}
          target="_blank"
          rel="noreferrer"
          className="cursor-pointer bg-second px-3 py-2 rounded-full font-semibold mr-2"
        >
          Click here
        </a>
        to view the list of lot number payment status. Please text the
        management to do your outstanding payment otherwise your building design
        plan will not be approved by the board
      </div>
      <a
        href={linkPDF?.value}
        target="_blank"
        rel="noreferrer"
        className={
          "mt-8 rounded-lg bg-white text-primary " +
          "cursor-pointer rounded-3xl py-3 text-center px-5"
        }
      >
        Click here to view the annual assessment notice letter.
      </a>
    </div>
  );
};

export default AssessmentBeranda;
