import { useEffect, useState } from "react";
import { ReactCookieProps, withCookies } from "react-cookie";
import { Outlet } from "react-router-dom";
import { isMobile } from "../../../helpers/constant";
import Header from "./header";
import Sidebar from "./sidebar";
// import axios from "axios";

const { REACT_APP_STORAGE_KEY = "" } = process.env;

const AdminLayout = ({ cookies }: ReactCookieProps) => {
  const [show, setShow] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [mobile, setMobile] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const checkAuth = async () => {
    try {
      const token = cookies?.get(REACT_APP_STORAGE_KEY || "");
      if (!token) throw new Error("unauthorized");

      // await axios.get(`${REACT_APP_API}/validate/${token}`);
      return setShow(true);
    } catch (error) {
      console.log(error);
      cookies?.remove(REACT_APP_STORAGE_KEY);
      return window.location.replace("/");
    }
  };

  useEffect(() => {
    checkAuth();

    const mobile_ = isMobile();
    setSidebarOpen(!mobile_);
    setMobile(mobile_);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (show) {
    return (
      <div className="min-h-screen bg-neutral-100">
        <div
          className={
            "fixed h-screen overflow-auto bg-white transition-all duration-300 " +
            (sidebarOpen
              ? "left-0 "
              : mobile
              ? "left-[-310px] "
              : "left-[-260px] ") +
            (mobile ? "w-[310px]" : "w-[260px]")
          }
        >
          <Sidebar />
        </div>

        <div
          className={
            "transition-all duration-300 " +
            (sidebarOpen ? "ml-[260px]" : "ml-0")
          }
        >
          <Header
            sidebarOpen={sidebarOpen}
            toggleSidebar={toggleSidebar}
            cookies={cookies}
          />
          <div className="relative h-full w-full pt-[80px] px-[20px]">
            {mobile && sidebarOpen ? <div /> : <Outlet />}
          </div>
        </div>
      </div>
    );
  }

  return <div />;
};

export default withCookies(AdminLayout);
