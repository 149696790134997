const HeroBeranda = () => {
  return (
    <div className={"bg-primary py-5"}>
      <div className="flex flex-col lg:flex-row items-center lg:px-[150px] px-[0px] justify-between">
        <div className="text-[20px] lg:text-[36px] font-[600] text-[#fff] text-center lg:text-left whitespace-nowrap">
          <div>Tanjung Ocean View</div>
          <div className="lg:mt-3 mt-1 mb-3">Association (TOVA)</div>
        </div>
        <div className="flex justify-center">
          <img src="/img/map.png" alt="map" className="w-[80%] lg:w-full" />
        </div>
      </div>
    </div>
  );
};

export default HeroBeranda;
