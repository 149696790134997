import { Link } from "react-router-dom";

const classname =
  "transition-all duration-300 py-[12px] font-[400] flex flex-row items-center gap-1 ";

function Sidebar() {
  return (
    <div className="flex flex-col p-[20px] text-neutral-500">
      <div className="my-3">NAVIGASI</div>
      <Link className={classname} to="/#subdivision">
        <i className="ri-bar-chart-grouped-line w-[20px]"></i>
        TOV Subdivision
      </Link>
      <Link className={classname} to="/#assessment">
        <i className="ri-file-list-line w-[20px]"></i>
        Annual Assessment
      </Link>
      <Link className={classname} to="/#articles">
        <i className="ri-award-line w-[20px]"></i>
        Articles
      </Link>
      {/* <Link className={classname} to="/#board">
        <i className="ri-database-line w-[20px]"></i>
        Board of TOVA
      </Link> */}
      <Link className={classname} to="/#contact">
        <i className="ri-chat-1-line w-[20px]"></i>
        Contact Us
      </Link>
    </div>
  );
}

export default Sidebar;
