/* eslint-disable */
import DOMPurify from "dompurify";
import axios, { AxiosRequestConfig } from "axios";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { Cookies } from "react-cookie";
import { storage } from "./firebaseConfig";
import { notification } from "antd";

const cookies = new Cookies();
const { REACT_APP_STORAGE_KEY = "", REACT_APP_API = "" } = process.env;

export const isMobile = () => {
  let check = false;
  try {
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor);
  } catch (error) {}
  return check;
};

export const getSortText = (text: string, max: number = 2) => {
  try {
    const parser = new DOMParser();
    const plainText = parser.parseFromString(
      DOMPurify.sanitize(text),
      "text/html"
    ).documentElement.textContent;

    return (
      plainText
        ?.split("")
        .map((item, index) => {
          if (index < max) {
            return item;
          }
        })
        .join("") + (text.length > max ? " ..." : "")
    );
  } catch (error) {
    console.error(error);
    return text;
  }
};

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const currencyFormat = (num: number | string, numberOnly?: boolean) => {
  if (typeof num === "string") num = Number(num);
  const result = num?.toFixed(0)?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  if (numberOnly) return result;
  return `Rp. ${result}`;
};

export const generateStringNumber = (num: number | string, leng: number) => {
  if (num.toString().length < leng) {
    let result = "";
    for (let i = 0; i < leng - num.toString().length; i++) {
      result += "0";
    }
    return result + num;
  }
  return num.toString();
};

export const reformatPhoneNumber = (num: string) => {
  return num?.replace(/^(\d{3})(\d{4})(\d{4})$/, "+62 $1-$2-$3") || "";
};

export const convertToSlug = (str: string) => {
  return str
    ?.toLowerCase()
    .replace(/[^\w ]+/g, "")
    .replace(/ +/g, "-");
};

export const validationImage = (
  file: any,
  maxSize = 2,
  ratioWidth = 16,
  ratioHeight = 9
) => {
  if (!file?.size) {
    return notification.error({
      message: "File gambar tidak valid!",
    });
  }

  const maxFileSize = maxSize * 1024 * 1024;

  if ((file?.size || 0) > maxFileSize) {
    return notification.error({
      message: `Ukuran file maksimal ${maxFileSize} MB!`,
    });
  }

  return true;
};

export const uploadFile = ({
  file,
  setProgress,
  setImage,
  prefix = "files",
}: {
  file: any;
  setProgress?: (e: number) => void;
  setImage: (e: string) => void;
  prefix?: string;
}) => {
  if (!file) return;

  const storageRef = ref(storage, `${prefix}/${convertToSlug(file.name)}`);
  const uploadTask = uploadBytesResumable(storageRef, file);

  uploadTask.on(
    "state_changed",
    (snapshot) => {
      const progress = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      );
      setProgress && setProgress(progress);
    },
    (error) => {
      alert(error);
    },
    () => {
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        setImage && setImage(downloadURL);
      });
    }
  );
};

export const restApi = async ({
  path,
  method,
  data,
}: {
  path: string;
  method: string;
  data?: any;
}) => {
  try {
    const token = cookies?.get(REACT_APP_STORAGE_KEY);

    let config: AxiosRequestConfig = {
      baseURL: `${REACT_APP_API}${path}`,
      method,
      headers: { Authorization: "Bearer " + token },
      data,
    };

    return await axios(config);
  } catch (error: any) {
    if (error?.response?.status === 401) {
      cookies?.remove(REACT_APP_STORAGE_KEY);
      window.location.replace("/");
    }
    return error?.message;
  }
};
