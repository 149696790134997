import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { isMobile } from "../../../../helpers/constant";
import Sidebar from "./sidebar";

const HeaderGlobal = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [mobile, setMobile] = useState(true);

  useEffect(() => {
    const mobile_ = isMobile();
    setSidebarOpen(!mobile_);
    setMobile(mobile_);
  }, []);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <>
      {mobile && (
        <div
          className={
            "fixed h-screen overflow-auto bg-white transition-all duration-300 " +
            (sidebarOpen ? "left-0 " : "left-[-310px]")
          }
        >
          <Sidebar />
        </div>
      )}
      <div
        className={
          "transition-all duration-300 sticky top-0 bg-white z-50 " +
          "border-b-2 border-b-gray-200 " +
          (sidebarOpen && mobile ? "ml-[260px]" : "ml-0")
        }
      >
        <div
          className={
            "h-[80px] lg:px-[150px] px-[20px] flex justify-between items-center"
          }
        >
          <div
            className="flex items-center cursor-pointer"
            onClick={() => window.location.replace("/")}
          >
            <img src={"/logo.png"} alt="logo" className="w-[100px]" />
          </div>
          {mobile ? (
            <div className="grid">
              <button
                onClick={toggleSidebar}
                className="p-[16px] transition-all duration-300"
              >
                <FontAwesomeIcon icon={faBars} color={"#344054"} />
              </button>
            </div>
          ) : (
            <div className="flex items-center cursor-pointer justify-around w-full mt-3 lg:mt-0 lg:w-[580px]">
              <Link to={"/#subdivision"}>
                <div className="lg:mr-[32px] text-primary font-semibold">
                  TOV Subdivision
                </div>
              </Link>
              <Link to={"/#assessment"}>
                <div className="lg:mr-[32px] text-primary font-semibold">
                  Annual Assessment
                </div>
              </Link>
              <Link to={"/#articles"}>
                <div className="lg:mr-[32px] text-primary font-semibold">
                  Articles
                </div>
              </Link>
              {/* <Link to={"/#board"}>
                <div className="lg:mr-[32px] text-primary font-semibold">
                  Board of TOVA
                </div>
              </Link> */}
              <Link to={"/#contact"}>
                <div
                  className={
                    "text-primary font-semibold lg:bg-primary py-2 px-5 rounded-3xl " +
                    "lg:text-[#fff]"
                  }
                >
                  Contact Us
                </div>
              </Link>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default HeaderGlobal;
